import React from 'react';
import './PrivacyPolicy.css';

const PrivacyPolicy: React.FC = () => {
    return (
        <div className="privacy-policy-screen">
            <h1>Privacy Policy</h1>
            <p>Last updated: May 15, 2024</p>

            <p>Welcome to AURA, an AI dating assistant created by BloomByte, LLC ("we," "us," or "our"). We are dedicated to safeguarding your privacy and ensuring the security of your data. This Privacy Policy explains how we handle the information you provide when using AURA.</p>

            <h2>1. Data Collection and Use</h2>
            <ul>
                <li><strong>AI Processing:</strong> AURA utilizes a third-party AI service to analyze user inputs and generate suitable conversation replies. This service processes the data provided by users to deliver personalized responses.</li>
                <li><strong>User Data Storage:</strong> AURA and BloomByte may store data submitted through the app to improve our AI service.</li>
            </ul>

            <h2>2. Security Measures</h2>
            <ul>
                <li><strong>Encryption:</strong> We implement encryption for data transmissions between your device and our third-party AI service to ensure that your information remains private and secure.</li>
                <li><strong>Limited Access:</strong> Access to the third-party AI service is managed to prevent unauthorized use of your data.</li>
            </ul>

            <h2>3. Your Privacy Rights</h2>
            <p>We value your trust and strive to maintain transparency regarding your data processing. If you have any questions or concerns about our data practices or your privacy rights, we are here to help.</p>

            <h2>4. Contact Us</h2>
            <p>For inquiries or further details on this Privacy Policy, you can reach out to us at admin@bloombyte.net. We are happy to provide any information you need to ensure your experience with AURA meets your expectations.</p>

            <p>Thank you for choosing <strong>AURA</strong> as your AI dating assistant!</p>
        </div>
    );
}

export default PrivacyPolicy;
