import React from 'react';
import './DownloadButton.css';

function DownloadButton() {
  const appStoreLink = 'https://apps.apple.com/us/app/aura-ai-pickup-artist-wingman/id6480233912';

  const handleClick = () => {
    window.open(appStoreLink, '_blank');
  };

  return (
    <div className="DownloadButton">
      <button onClick={handleClick}>
        <img src="/Apple_Download.svg" alt="Download on the App Store" />
      </button>
    </div>
  );
}

export default DownloadButton;