import React from 'react';
import './Contact.css';

const Contact: React.FC = () => {
    return (
        <div className="contact-screen">
            <h1>Contact Us</h1>
            <p>If you have any questions or need further information, please feel free to contact us at:</p>
            <p>Email: <a href="mailto:admin@bloombyte.net" style={{ color: 'white' }}>admin@bloombyte.net</a></p>
        </div>
    );
}

export default Contact;
