import React from 'react';
import './Footer.css';

const Footer = () => {
    return (
        <div className="footer">
            <a href="/about">About</a>
            <a href="/privacy">Privacy</a>
            <a href="/contact">Contact</a>
        </div>
    );
}

export default Footer;