import React from 'react';
import './About.css';

const About: React.FC = () => {
    return (
        <div className="about-screen">
            <div className="about-content">
                <h1>About AURA</h1>
                <p>
                    You might have noticed AURA's style is different than that of other AI Dating Assistants. 
                    This is because AURA imbues you with STYLE, UNWAVERING BOLDNESS, and a TAKE IT OR LEAVE IT attitude—the 
                    kind that people flock to. We believe the right attitude counts more than all the material 
                    possessions in the world.
                </p>
                <p>
                    We, at AURA, don't believe in traditional flirting methods like icebreakers or pickup lines. 
                    We believe in FLIPPING THE SCRIPT. Don't chase the girl; make her chase you. The next time 
                    AURA generates a response that puts a girl back on her heels, teases her, makes fun of her, 
                    just know, that's the one to send.
                </p>
            </div>
        </div>
    );
}

export default About;