import React from 'react';
import './Hero.css';
import DownloadButton from './DownloadButton';

function Hero() {
  return (
    <section className="Hero">
      <h2>Your AI Dating Assistant</h2>
      <DownloadButton />
    </section>
  );
}

export default Hero;