import { Route, Routes } from 'react-router-dom';
import './App.css';
import Header from './components/Header';
import Hero from './components/Hero';
import Footer from './components/Footer';
import About from './components/About';
// Import other components as needed

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/about" element={<About />} />
        <Route path="/privacy" element={<div>Privacy Page</div>} />
        <Route path="/contact" element={<div>Contact Page</div>} />
        <Route path="/" element={
          <>
            <Header />
            <Hero />
            <Footer />
          </>
        } />
      </Routes>
    </div>
  );
}

export default App;